<template>
  <div>
    <div v-show="$route.name === 'courseStep'">
      <div class="btn-box flex">
        <a-button class="btn" icon="plus" type="primary" @click="modalVisible=true">新建阶段</a-button>
        <a-button class="btn" type="primary" @click="describeVisible=true">阶段描述文案</a-button>
      </div>
      <!-- 表格 -->
      <a-table :columns="columns" :data-source="tableData" row-key="stageNo" :pagination="false" :loading="tableLoading">
        <span slot="number" slot-scope="number, record, index">
          <span>{{ index + 1 }}</span>
        </span>
        <span slot="operation" slot-scope=" operation, record">
          <a class="margin_right20" @click="onPut(record)">{{ record.status ? '下架' : '上架' }}</a>
          <a class="margin_right20" @click="showModal(record)">编辑</a>
          <router-link :to="{ name: 'secondTags', params: {courseNo: courseNo, stageNo: record.stageNo}}" class="margin_right20">标签管理</router-link>
        </span>
      </a-table>
      <!-- 添加/编辑 -->
      <a-modal
        title="添加/编辑"
        :visible="modalVisible"
        :confirm-loading="confirmLoading"
        :footer="null"
        :destroy-on-close="true"
        @cancel="onCancel"
      >
        <a-form-model
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="阶段名称" ref="stageTitle" prop="stageTitle">
            <a-input v-model="ruleForm.stageTitle" placeholder="请输入阶段名称" />
          </a-form-model-item>
          <a-form-model-item label="排序" ref="sort" prop="sort">
            <a-input v-model="ruleForm.sort" placeholder="数字越大，顺序越靠前" />
          </a-form-model-item>
          <a-form-model-item label="上架设置" ref="status" prop="status">
            <a-radio-group v-model="ruleForm.status">
              <a-radio :value="0">
                暂不上架
              </a-radio>
              <a-radio :value="1">
                立即上架
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submitForm('ruleForm')">
              确定
            </a-button>
            <a-button style="margin-left: 10px" @click="onCancel">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!-- 描述文案 -->
      <a-modal
        title="描述文案"
        :visible="describeVisible"
        :destroy-on-close="true"
        @cancel="onCancel"
        @ok="onSaveDescribe"
      >
        <a-textarea v-model="describe" placeholder="请输入描述文案" />
        <div v-show="!describe.trim()" style="color: red">请输入描述文案</div>
      </a-modal>
      <UploadImage :is-show="showUpdateImgModal" :tag-no="ruleForm.tagNo" :title="ruleForm.modalTitle" @close="() => showUpdateImgModal = false" />
    </div>
    <router-view />
  </div>
</template>
<script>
import { courseModuleStageList, getCourseModuleStageDescribe, setCourseModuleStageDescribe, addCourseModuleStage,editCourseModuleStage, updateCourseModuleStageStatus } from '@/request/api/coursManage'
import { courseStageColumns } from '../constant'
import UploadImage from './components/Picture.vue'
import { message } from 'ant-design-vue'
const validateSort = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入排序'));
  } else if (isNaN(+value)) {
    callback(new Error('请输入合法数字'));
  } else {
    callback()
  }
}
export default {
  name: 'StudyPlan',
  components: { UploadImage },
  data() {
    return {
      describe: '', // 描述文案
      describeVisible: false,
      showUpdateImgModal: false,
      courseNo: this.$route.params.courseNo,
      moduleId: this.$route.params.moduleId,
      columns: courseStageColumns,
      tableLoading: false,
      tableData: [],
      modalVisible: false,
      confirmLoading: false,
      ruleForm: {
        tagTitle: '',
        sort: 0,
        status: 1
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      
      rules: {
        tagTitle: [
          { required: true, message: '请输入标签名称', trigger: 'blur' },
        ],
        sort: [
          { required: true, validator: validateSort ,trigger: 'change' },
        ],
        status: [{required: true,}],
      },
    }
  },
  created() {
    this.fetchData()
    this.getDesc()
  },
  methods: {
    async getDesc() {
      const { code, data } = await getCourseModuleStageDescribe({ moduleId: this.moduleId })
      if (code !==200) return
      this.describe = data.descb
    },
    async fetchData() {
      const params = {
        moduleId: this.moduleId
      }
      const { code, data } = await courseModuleStageList(params)
      if (code !==200) return
      this.tableData = data
    },
    showModal(stage) {
      this.ruleForm = { ...stage },
      this.modalVisible = true
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          +this.ruleForm.stageNo ? this.edit() : this.add()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 添加
    async add() {
      const params = {
        moduleId: this.moduleId,
        ...this.ruleForm,
        sort: +this.ruleForm.sort
      }
      const { code } = await addCourseModuleStage(params)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.onCancel()
      this.fetchData()
    },
    // 编辑
    async edit() {
      const params = {
        ...this.ruleForm,
        sort: +this.ruleForm.sort
      }
      const { code } = await editCourseModuleStage(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.onCancel()
      this.fetchData()
    },
    // 取消/关闭
    onCancel() {
      this.ruleForm = {
        tagTitle: '',
        sort: 0,
        status: 1,
      },
      this.modalVisible = false
      this.describeVisible = false
    },
    // 上架
    async onPut(recode) {
      const params = {
        stageNo: recode.stageNo,
        status: recode.status ? 0 : 1
      }
      const {code} = await updateCourseModuleStageStatus(params)
      if (code !== 200) return
      const text = recode.status ? '下架' : '上架'
      this.$message.success(`${text}成功`)
      recode.status = recode.status ? 0 : 1
    },
    // 描述
    async onSaveDescribe() {
      if (!this.describe.trim()) return
      const params = {
        moduleId: this.moduleId,
        descb: this.describe
      }
      const { code } = await setCourseModuleStageDescribe(params)
      if (code !== 200) return
      message.success('添加成功')
      this.onCancel()
    }
  },
  filters: {
    getTagType(val) {
      let type = ''
      switch(val) {
        case 1:
          type = '课程-不分章节'
          break;
        case 2: 
          type = '课程-分章节'
          break;
        case 3: 
          type = '图片'
          break;
        case 4: 
          type = '跳转圈子'
          break;
      }
      return type
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.switch {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.btn {
  margin-right: 20px;
}
</style>